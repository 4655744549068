<template>
  <section :class="mode == 'editor' ? '' : 'resizable'">
    <div
      class="box"
      :style="{
        height: panelHeight + 'px'
      }"
      :class="isEditing ? 'box-warning' : 'box-primary'"
    >
      <div class="nav-tabs-custom">
        <ul class="nav nav-tabs pull-right ui-sortable-handle">
          <!-- /Tabs -->
          <li
            v-bind:class="{ active: tab == 0 }"
            v-if="toolbar.reportTab"
            v-show="
              !downloadOnly &&
              isReady &&
              dropdownOptions &&
              dropdownOptions.length
            "
            :style="{ opacity: isEditing ? '.4' : '1' }"
          >
            <a
              class="equipment-data-panel-tab"
              aria-expanded="true"
              v-on:click.prevent.stop="setTab(0)"
              v-bind:disabled="isSwitching"
              :title="$t('titles.view_table')"
            >
              <i class="fa fa-table"></i>
            </a>
          </li>
          <li
            v-bind:class="{ active: tab == 1 }"
            v-if="toolbar.chartTab"
            v-show="
              !downloadOnly &&
              isReady &&
              dropdownOptions &&
              dropdownOptions.length
            "
            :style="{ opacity: isEditing ? '.4' : '1' }"
          >
            <a
              class="equipment-data-panel-tab"
              aria-expanded="true"
              v-on:click.prevent.stop="setTab(1)"
              v-bind:disabled="isSwitching"
              :title="$t('titles.view_graph')"
            >
              <i class="fa fa-line-chart"></i>
            </a>
          </li>
          <!-- \tabs -->

          <!-- /Toolbar -->
          <li v-if="!toolbar.alwaysVisible">
            <a
              class="equipment-data-panel-tab"
              :title="$t('show_toolbar')"
              @click.stop.prevent="toolbarVisible = !toolbarVisible"
            >
              <i
                class="fa fa-navicon"
                :class="{ 'text-primary': toolbarVisible }"
              ></i>
            </a>
          </li>
          <li class="pull-left header" style="margin-right: 0">
            <DashboardPanelTitle
              :panel="panel"
              :connector="equipment"
              :isEditing="isEditing"
              @click.stop.prevent="isEditing && $emit('panelProperties')"
              style="vertical-align: top"
            />
            <div
              class="tab-toolbar-control-group ml-5"
              v-show="
                (isReady || mode == 'editor') &&
                (toolbar.alwaysVisible || toolbarVisible)
              "
            >
              <!-- calendar -->
              <div
                class="tab-toolbar-control mr-5"
                v-if="toolbar.calendarUIConfig != 'none'"
                style="width: auto"
              >
                <DatetimeRangePicker
                  v-if="showDatetimePicker"
                  :startRef="startRef"
                  :endRef="endRef"
                  :min="minRef"
                  :uiConfig="toolbar.calendarUIConfig"
                  :shortcuts="toolbar.calendarShortCuts"
                  :allowFutureDate="false"
                  :showRefreshIcon="endsNow"
                  fontSize="64%"
                  @interval-changed="onIntervalChanged"
                  @dateRangePickerEvent="onDateRangePickerEvent"
                ></DatetimeRangePicker>
              </div>

              <!-- largelongIntervalSelection filter -->
              <div
                class="tab-toolbar-control"
                v-if="canEdit && toolbar.largelongIntervalSelection"
              >
                <IntervalDropDown v-model="queryLimit" />
              </div>
              <!-- data filter -->
              <div
                class="tab-toolbar-control"
                v-if="toolbar.dataFilter && !downloadOnly"
              >
                <dropdown
                  v-if="canEdit && dropdownOptions && dropdownOptions.length"
                  position="right"
                  :data="dropdownOptions"
                  :multiple="true"
                  :showAll="true"
                  :title="$t('titles.filter_by_data')"
                  @apply="dropdownApply"
                >
                  <span>
                    <i class="fa fa-filter toolbar-btn-icon"></i>
                  </span>
                </dropdown>
              </div>
              <div
                class="tab-toolbar-control"
                v-if="showDownloadButton && !downloadOnly"
              >
                <DownloadButton
                  v-if="tableId"
                  :tableId="tableId"
                  @ready="downloading = true"
                  @done="downloading = false"
                  :downloading="downloading"
                  :xls="toolbar.downloadXLS"
                  :csv="toolbar.downloadCSV"
                  style="z-index: 10"
                />
              </div>
            </div>
          </li>
          <!-- \Toolbar -->
        </ul>
      </div>
      <div
        v-if="isReady"
        class="no-padding tab-content"
        :class="{
          active: tab == 0,
          'tab-content-legacy': !display.render_version
        }"
        :style="{
          overflow: tab == 1 ? 'hidden' : 'inherit'
        }"
        ref="tabSlot"
      >
        <template
          v-if="
            dataset &&
            dataset.length &&
            historyEnabledDataList.length >=
              contract.data_history_short_max_registers
          "
        >
          <div class="text-warning text-center">
            <i class="fa fa-warning"></i>
            {{ $t("history_data_limit_reached") }}
          </div>
        </template>
        <template v-if="downloadOnly">
          <div
            style="margin: 10px 20px 20px 20px"
            class="active tab-pane download-container"
          >
            <HistoryDownloadContainer
              v-if="downloadOnly && showDatetimePicker"
              :startDate="startDate"
              :endDate="endDate"
              :items="historyEnabledDataList"
              :selected="selected"
              :isDatetimePickerVisible="isDatetimePickerVisible"
              idFieldName="data_ids"
            />
          </div>
        </template>
        <template v-else>
          <div v-if="dataset && dataset.length" class="active tab-pane">
            <keep-alive>
              <EquipmentHistoryChartDisplay
                v-if="tab"
                ref="chartDisplay"
                :widgetOptions="chartOptions"
                :dataset="chartDataSet"
                :display="display"
                :fields="fields"
                :equipment="equipment"
                :selected="selected"
                v-model="selected"
              />
              <EquipmentHistoryReportDisplay
                v-else
                :dataset="reportDataset"
                :display="display"
                :selectedData="selectedFields"
                :equipment="equipment"
                :selected="selected"
                :downloading="downloading"
                :dataSettings="fields"
                :missingValues="missingValues"
                :tableId="tableId"
                v-model="selected"
                :style="{
                  height: isDatetimePickerVisible ? '100px' : 'inherit',
                  overflow: isDatetimePickerVisible ? 'hidden' : 'inherit'
                }"
              />
            </keep-alive>
          </div>
          <div v-else class="active tab-pane">
            <EquipmentChartHistoryNoData></EquipmentChartHistoryNoData>
          </div>
        </template>
      </div>
      <Spin v-if="busy" />
    </div>
  </section>
</template>

<script>
import { createHistory } from "@/modules/history.js";

import DownloadButton from "@/components/download-button.vue";
import Dropdown from "@/plugins/dropdown/dropdown.vue";
import DatetimeRangePicker from "@/components/datetime-range-picker.vue";
import EquipmentChartHistoryNoData from "@/components/equipment-chart-history-no-data.vue";
import EquipmentHistoryChartDisplay from "@/components/equipment-history-chart-display.vue";
import EquipmentHistoryReportDisplay from "@/components/equipment-history-report-display.vue";
import DataService from "@/services/data.js";
import Spin from "@/components/spin.vue";
import IntervalDropDown from "@/components/widgets/interval-dropdown.vue";
import HistoryDownloadContainer from "@/components/history/history-download-container.vue";
import DashboardPanelTitle from "@/components/dashboard-panel-title.vue";
import HistoryForm from "@/components/control-sidebar/property-editors/history-form.vue";
import { defaultPanelOptions } from "@/components/control-sidebar/property-editors/detail-form-history.vue";

const nowRounded = function() {
  let value = moment();
  // value.minute(Math.round(value.minute() / 30) * 30);
  value.second(0);
  return value;
};

export default {
  name: "EquipmentHistoryPanel",
  props: {
    equipment: {
      type: Object,
      required: false,
      default: () => null
    },
    display: {
      type: Object,
      required: true
    },
    panelName: {
      type: String,
      required: true
    },
    screenId: {
      type: [String, Number],
      required: false,
      default: () => 0
    },
    title: {
      type: String,
      default: "history",
      required: false
    },
    mode: {
      type: String,
      default: "viewer",
      required: false
    },
    isEditing: {
      type: Boolean,
      required: false,
      default: () => false
    },
    panel: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  components: {
    DownloadButton,
    DatetimeRangePicker,
    EquipmentHistoryChartDisplay,
    EquipmentHistoryReportDisplay,
    EquipmentChartHistoryNoData,
    IntervalDropDown,
    HistoryDownloadContainer,
    Dropdown,
    Spin,
    DashboardPanelTitle
  },
  data: function() {
    return {
      mounted: false,
      isReady: false,
      isSwitching: false, // swiching components
      startDate: null, // todo: move it to the selection component
      endDate: null,
      dataset: null,
      startRef: null,
      endRef: null,
      minRef: 0,
      reportDataset: null,
      selected: [],
      mEquipmentDataList: null,
      tab: 0,
      defaultHeight: this.display.render_version ? 270 : 400,
      //contentHeight: 0,
      downloading: false,
      queryLimit: 0,
      showDatetimePicker: false,
      toolbarVisible: false,
      isDatetimePickerVisible: false,
      showChart: true,
      endsNow: false,
      tableId: this.$utils.uuid()
    };
  },
  computed: {
    dashboardDataList() {
      return this.$store.getters["dashboard/dataList"] || [];
    },
    canEdit() {
      let user = this.$store.getters["user/loggedUser"] || null;
      return user ? !user.is_public_access : false;
    },
    contract() {
      let user = this.$store.getters["user/loggedUser"];
      return (user && user.contract) || null;
    },
    queryLimits() {
      let lst = this?.contract
        ? [
          this.contract.history_short_limit_to_query,
          this.contract.history_long_limit_to_query
        ]
        : [0, 0];
      return lst.map((item, i) => {
        return {
          label: `${this.$t(i == 0 ? "chart" : "download")} (${this.$t(
            "up_to"
          )} ${item} ${this.$t("days")})`,
          value: item,
          selected: item == this.queryLimit
        };
      });
    },
    downloadOnly() {
      return this.queryLimit == this?.contract?.history_long_limit_to_query;
    },
    panelOptions() {
      return this?.panel?.options || null;
    },
    namespace() {
      return this.panelName == "equipment_history" || this.mode == "editor"
        ? "history"
        : this.panelName;
    },
    equipmentDataList: {
      set(items) {
        let lst = JSON.parse(
          JSON.stringify((items || []).filter((i) => i.history_enabled))
        );
        let custom_view =
          (this.equipment &&
            this.equipment.portal_data &&
            this.equipment.portal_data.custom_history_data_list) ||
          null;
        if (custom_view && lst.length) {
          // begin: make it compatible with previous version (devicename+dataname)
          let setReferenceId = function(list) {
            for (var i in list || []) {
              let data = lst.find((item) => {
                return item.device.name + "." + item.name == list[i];
              });
              if (data) {
                list[i] = data.reference_id;
              }
            }
          };
          setReferenceId(custom_view.excluded || []);
          setReferenceId(custom_view.checked || []);
          if (custom_view.order_type == "manual") {
            setReferenceId(custom_view.order || []);
            lst.forEach((i) => {
              if (custom_view.order.indexOf(i.reference_id) == -1) {
                custom_view.order.push(i.reference_id);
              }
            });
          }
          // end
        }
        this.$set(this, "mEquipmentDataList", lst);
      },
      get() {
        return this.mEquipmentDataList;
      }
    },
    historyConfiguration() {
      let cfg = Object.assign(
        {
          interval: -24,
          unit: "hour",
          colors: this.$utils.colors(),
          decimals: 0,
          format: "",
          nSelected: 3,
          chart: {
            trend: false,
            type: "line",
            scale: "left"
          }
        },
        this.display.history || {}
      );
      return cfg;
    },
    dataList() {
      let self = this;
      let lst = (self.equipmentDataList || []).filter(function(item) {
        return self.selected.indexOf(item.id) >= 0;
      });
      return lst;
    },
    historyEnabledDataList() {
      return (this.equipmentDataList || []).filter(
        ({ history_enabled }) => history_enabled
      );
      // return this.alarmList;
    },
    hasDataList() {
      return this.$store.getters["dashboard/hasResourceFrom"](
        "data",
        this.equipment?.id
      );
    },
    deviceId() {
      return this.$store.getters.deviceId;
    },
    fields() {
      /*
      initially it validates options.data from screens/n.json
      when no data is found (preset) it would consider
      the list of history enabled data
      from equipmentDataList
      */
      let fields = JSON.parse(JSON.stringify(this.panelDataList));
      if (fields.length == 0 || this.deviceId) {
        fields = (this.equipmentDataList || [])
          .filter((i) => {
            return i.history_enabled && this.deviceId
              ? i.device.id == this.deviceId
              : true;
          })
          .map((i) => ({
            data_id: i.id,
            data: i
          }));
      }
      let formatList = {};
      this.$root.config.references.data_value_format_types.forEach((i) => {
        formatList[i.id] = i.format_mask;
      });
      let cfg = this.historyConfiguration;
      let colors = cfg.colors.slice(0);
      fields = fields.map((i) => {
        if (!i.data) {
          i.data = (this.equipmentDataList || []).find(
            ({ id }) => id == i.data_id
          );
        }
        return {
          ...i,
          ...{
            decimals: cfg?.decimals || 0,
            format:
              i?.data?.custom_format ||
              (i?.data?.value_format_type?.id || 0
                ? formatList[i?.data.value_format_type.id]
                : cfg.format),
            value_format_type: i?.data?.value_format_type || null,
            text_list_id: i?.data?.text_list?.id || "",
            refreshInterval: 0,
            label: i?.chartOptions?.name || i?.data?.name || "",
            selected:
              this.selected && this.selected.length
                ? this.selected.indexOf(i?.data?.id) >= 0
                : false,
            chart: {
              type: cfg.chart.type || "line",
              color:
                i?.data?.portal_data?.color &&
                  i?.data?.portal_data?.color != "inherit"
                  ? i?.data?.portal_data?.color
                  : colors.shift(),
              trend: cfg.chart.trend || false,
              scale: cfg.chart.scale || "left"
            },
            default: cfg.default || 0
          }
        };
      });
      return fields;
    },
    selectedFields() {
      var self = this;
      let order = this.dropdownOptions
        .filter((i) => i.selected)
        .map((i) => i.label);
      var selected = self.selected && self.selected.length ? self.selected : [];
      var fields = self.fields;
      var lst = fields.filter(function(item) {
        return selected.indexOf(item.data_id) >= 0;
      });
      // .map((i) => {
      //   i.index = order.indexOf(i.label);
      //   return i;
      // })
      // .sort((a, b) => {
      //   if (a.index > b.index) return 1;
      //   if (b.index > a.index) return -1;
      //   return 0;
      // });
      return lst;
    },
    dropdownOptions() {
      var self = this;
      // panel filter
      let lst = [];
      if (this.panelDataList?.length) {
        this.panelDataList.forEach((cfg) => {
          let field = this.fields.find(({ data_id }) => data_id == cfg.data_id);
          let data = (field && this.equipmentData(field.data_id)) || null;
          if (field && data) {
            let title = `#${field.data_id} ${data.device.name} - ${data.name}`;

            if (data.identity_embedded_app) {
              title += ` - ${this.$t("titles.identity_embedded_app")}: ${data.identity_embedded_app}`
            }

            lst.push({
              data: data,
              label: field.label,
              value: field.data_id,
              title: title,
              selected: this.selected.indexOf(field.data_id) >= 0
            });
          }
        });
        return lst;
      }

      // default filter (from connector)
      lst = self.fields.map(function(item) {
        let data = self.equipmentData(item.data_id) || null;
        return {
          label: item.label,
          value: item.data_id,
          title: `#${item.data_id} ${data.device.name} - ${data.name}`,
          data: data,
          selected: self.selected.indexOf(item.data_id) >= 0
        };
      });

      let custom_view =
        (self.equipment &&
          self.equipment.portal_data &&
          self.equipment.portal_data.custom_history_data_list) ||
        null;

      if (custom_view && lst.length) {
        // begin: make it compatible with previous version (devicename+dataname)
        let setReferenceId = function(list) {
          for (var i in list || []) {
            let data = lst.find((item) => {
              return item.data.device.name + "." + item.data.name == list[i];
            });
            if (data) {
              list[i] = data.data.reference_id;
            }
          }
        };
        setReferenceId(custom_view.excluded || []);
        setReferenceId(custom_view.checked || []);
        if (custom_view.order_type == "manual") {
          setReferenceId(custom_view.order || []);
        }
        // end
        if (custom_view.order_type == "column") {
          let asc = custom_view.order.asc;
          let attr = custom_view.order.column || "name";
          lst.sort(function(a, b) {
            if (attr in a.data && attr in b.data) {
              if (
                asc ? a.data[attr] > b.data[attr] : b.data[attr] > a.data[attr]
              )
                return 1;
              if (
                asc ? b.data[attr] > a.data[attr] : a.data[attr] > b.data[attr]
              )
                return -1;
            }
            return 0;
          });
        } else if (custom_view.order_type == "manual") {
          // new items are added to the end of the list
          lst.forEach((i) => {
            if (custom_view.order.indexOf(i.data.reference_id) == -1) {
              custom_view.order.push(i.data.reference_id);
            }
          });
          lst.sort((a, b) => {
            // custom_view.order.indexOf(self.dataPath(a.data)) -
            // custom_view.order.indexOf(self.dataPath(b.data))
            let ia = custom_view.order.indexOf(self.dataPath(a.data));
            let ib = custom_view.order.indexOf(self.dataPath(b.data));
            if (ia > ib) return 1;
            if (ib > ia) return -1;
            return 0;
          });
        }
        if ((custom_view.excluded || []).length) {
          lst = lst.filter(
            (item) => !custom_view.excluded.includes(self.dataPath(item.data))
          );
        }
      }
      return lst;
    },
    task() {
      let task = this.$store.getters["equipmentData/task"] || null;
      if (task) {
        if (task.timestamp) {
          let expiresAt = new Date(task.timestamp + 20 * 60 * 60000); // + 20h
          if (new Date().getTime() > expiresAt) {
            return null;
          }
        }
        if (task?.data_ids) {
          let invalid = (task.data_ids.split(",") || []).some((id) => {
            return !(this.equipmentDataList || []).some(
              (item) => id == item.id
            );
          });
          if (invalid) {
            return null; // task belong to another conector
          }
        }
      }
      // valid task
      return task;
    },
    portalData() {
      let user = this.$store.getters["user/loggedUser"] || {};
      return user?.user_profile?.portal_data || null;
    },
    largePanel() {
      return (
        this.$store.getters["dashboard/expandedPanel"] ||
        this.$store.getters["dashboard/fullscreenPanel"] ||
        ""
      );
    },
    isLarge() {
      return this.largePanel && this.panel.name == this.largePanel;
    },
    busy() {
      // return this.$store.getters["history/busy"] || false;
      return !this.mounted || this.$store.getters[`${this.namespace}/busy`];
    },
    history() {
      // return this.$store.getters["history/entries"] || null;
      return this.$store.getters[`${this.namespace}/entries`] || null;
    },
    panelHeight() {
      let h = (this?.panel?.style || {})["min-height"] || undefined;
      return h ? h.replace(/px/, "") : h;
    },
    contentHeight() {
      let navbar = 80;
      let toolbar = 50;
      if (this.isLarge) {
        let offset = navbar + toolbar;
        if (this.$store.getters["dashboard/fullscreenPanel"]) {
          offset = toolbar; // keep navbar only
        }
        return window.innerHeight - offset;
      } else {
        if (this.panelHeight) {
          return this.panelHeight - 100;
        } else {
          if ((this?.$el?.offsetHeight || 0) - toolbar > this.defaultHeight) {
            return this.$el.offsetHeight - toolbar;
          } else {
            return this.defaultHeight;
          }
        }
      }
    },
    sidebar() {
      return (
        this.$store.getters["dashboard/sidebar"] || {
          name: "unknown"
        }
      );
    },
    edges() {
      // return this?.panel?.options?.history?.edges || "leave_them_empty";
      var edges = (this?.panel?.options?.history || {})["edges"];
      return edges || "leave_them_empty";
    },
    missingValues() {
      var missingValues = (this?.panel?.options?.history || {})[
        "missingValues"
      ];
      return missingValues || "leave_them_empty";
    },
    panelDataList() {
      return this?.panelOptions?.dataList || this?.panelOptions?.data || []; // options.data = legacy
    },
    toolbar() {
      let cfg = {
        ...defaultPanelOptions().toolbar.items,
        ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar?.items || {}))
      };
      let toolbar = {
        alwaysVisible: this?.panelOptions?.toolbar?.alwaysVisible
          ? true
          : false,
        defaultTab: this?.panelOptions?.toolbar?.defaultTab,
        calendarUIConfig:
          cfg.calendarInput.enabled && cfg.calendarButton.enabled
            ? "both"
            : cfg.calendarInput.enabled
              ? "input"
              : cfg.calendarButton.enabled
                ? "button"
                : "none",
        calendarShortCuts: cfg.calendarIntervalRange.enabled,
        dataFilter: cfg.dataFilter.enabled,
        largelongIntervalSelection: cfg.largelongIntervalSelection.enabled,
        downloadButton: cfg.downloadCSV.enabled || cfg.downloadXLS.enabled,
        downloadCSV: cfg.downloadCSV.enabled,
        downloadXLS: cfg.downloadXLS.enabled,
        chartTab: cfg.chartTab.enabled,
        reportTab: cfg.reportTab.enabled
      };
      return toolbar;
    },
    toobarAlwaysVisible() {
      return this?.toolbar?.alwaysVisible || false;
    },
    defaultTab() {
      return this?.toolbar?.defaultTab || "chartTab";
    },
    showDownloadButton() {
      if (!this.isReady) return false;
      if (
        this.mode == "editor" &&
        (this.toolbar.alwaysVisible || this.toolbarVisible) &&
        this.toolbar.downloadButton
      ) {
        return true;
      }
      if (
        !this.downloadOnly &&
        this.canEdit &&
        this.dataset &&
        this.dataset.length &&
        this.tab == 0 &&
        this.toolbar.downloadButton
      ) {
        return true;
      }
      return false;
    },
    panelDataIdList() {
      if (this.panelDataList) {
        return (this.panelDataList || [])
          .filter(({ data_id }) => {
            return (this.dashboardDataList || []).some(
              ({ id }) => data_id == id
            );
          })
          .map(({ data_id }) => data_id)
          .sort();
      }
      return null;
    },
    chartOptions() {
      let small = window.innerWidth < 768;
      let legacy = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            animation: false
          },
          formatter: null
        },
        legend: {
          type: "scroll"
        },
        toolbox: {
          show: !small,
          orient: "vertical",
          top: 30,
          feature: {
            restore: {},
            dataZoom: {
              title: {
                zoom: this.$t("area_zoom"),
                back: this.$t("back")
              },
              emphasis: {
                iconStyle: {
                  textFill: "#fff",
                  textBackgroundColor: "rgba(50,50,50,0.7)",
                  textPadding: 5,
                  textPosition: "left"
                }
              }
            }
          }
        },
        xAxis: {
          type: "time",
          axisLabel: {
            formatter: null
          }
        },
        yAxis: {
          axisLabel: {
            formatter: null
          }
        },
        grid: {
          top: 35,
          right: 40,
          left: 40,
          bottom: 60,
          containLabel: true
        },
        dataZoom: [
          {
            show: !small,
            type: "slider",
            labelFormatter: null
          },
          {
            enabled: !small,
            type: "inside"
          }
        ],
        series: {
          type: "line",
          data: []
        }
      };
      for (var p in this?.panelOptions?.chartOptions || {}) {
        if (p == "toolbox") {
          legacy[p].show = this?.panelOptions?.chartOptions[p].show;
        } else {
          let n = JSON.parse(JSON.stringify(this.panelOptions.chartOptions[p]));
          if (typeof legacy[p] == "object" && typeof n == "object") {
            legacy[p] = { ...legacy[p], ...n };
          } else {
            legacy[p] = n;
          }
        }
      }
      return legacy;
    },
    chartDataSet() {
      return (this.dataset || []).filter(
        ({ dataType }) => dataType !== "string"
      );
    }
  },
  watch: {
    deviceId(n, o) {
      if (n != o) {
        if (
          this.fetchedAll ||
          (n &&
            (this.equipmentDataList || []).some(
              ({ device }) => device && device.id == n
            ))
        ) {
          this.initSelection();
          this.onFilterChanged();
          return;
        } else if (!this.fetchedAll) {
          this.equipmentDataRequest(true).then((resp) => {
            if (resp) {
              this.equipmentDataList = resp;
              this.setup();
              this.onFilterChanged();
            }
          });
          return;
        }
        this.isReady = false;
        this.$nextTick(() => {
          this.parseEquipmentDataSamples();
        });
      }
    },
    portalData: {
      handler() {
        this.queryLimit = 0;
        this.showDatetimePicker = false;
        this.$nextTick(() => {
          this.showDatetimePicker = true; // force the calendar to be mounted again
        });
      },
      deep: true
    },
    busy(n, o) {
      if (!n && o) {
        this.parseEquipmentDataSamples();
      }
    },
    equipment: {
      handler() {
        if (!this.isReady) {
          this.equipmentDataRequest(true).then((resp) => {
            if (resp) {
              this.equipmentDataList = resp;
              this.setup();
              this.onFilterChanged();
            }
          });
        }
      },
      deep: true,
      immediate: true
    },
    panelDataList: {
      handler(n) {
        if (n && n.length) {
          this.getPanelDataList().then((resp) => {
            if (resp) {
              this.equipmentDataList = resp;
              this.setup();
              this.onFilterChanged();
            }
          });
        } else if (this.mode == "editor") {
          this.setup();
          this.onFilterChanged();
        }
      },
      deep: true,
      immediate: true
    },
    isEditing: {
      handler(n) {
        if (n) {
          if (this.sidebar.name != "HistoryForm") {
            this.$emit("initCustomProperties", {
              panelName: this.panel.name,
              propertyEditor: HistoryForm
            });
          }
        }
      },
      immediate: true
    },
    fields: {
      handler() {
        if (this.isEditing) {
          this.parseEquipmentDataSamples();
        }
      },
      deep: true
    },
    toobarAlwaysVisible(n) {
      //editor mode
      if (!n) {
        this.toolbarVisible = false;
      }
    },
    defaultTab: {
      handler(n) {
        //editor mode
        if (n == "chartTab") {
          this.setTab(1);
        } else if (n == "reportTab") {
          this.setTab(0);
        }
      },
      immediate: true
    },
    chartOptions(n) {
      if (this.isEditing && this.$refs.chartDisplay) {
        this.$nextTick(() => {
          this.$refs.chartDisplay.createChart();
        });
      }
    },
    queryLimit(n) {
      if (n) {
        if (this.downloadOnly) {
          // this.selected = this.historyEnabledAlarmList.map(({ id }) => id);
        }
        this.showDatetimePicker = false;
        this.initIntervalRef();
        this.$nextTick(() => {
          this.showDatetimePicker = true; // force the calendar to be mounted again
        });
      }
    }
  },
  methods: {
    setTab(i) {
      let self = this;
      self.tab = i;
      self.isSwitching = true;
      setTimeout(function() {
        self.isSwitching = false;
      }, 5000);
    },
    updateEndsNow() {
      // important: can not be dynamic/reactive
      this.endsNow =
        moment().format("YYMMDDHHmm") == this.endDate.format("YYMMDDHHmm");
    },
    initIntervalRef() {
      // start
      let days = this.downloadOnly
        ? this.queryLimit
        : (this?.panelOptions?.history?.interval || 24) / 24;
      let interval = -1 * (Math.abs(days) * 24);
      let value = moment().add(interval, "hour");
      // value.minute(Math.round(value.minute() / 30) * 30);
      value.second(0);
      this.startRef = value;
      // end
      this.endRef = nowRounded();
      // max selectable interval (min date)
      this.minRef = -1 * (Math.abs(this.queryLimit || 30) * 24); // hour based calendar
      this.startDate = this.startRef;
      this.endDate = this.endRef;
      this.updateEndsNow();
    },
    onIntervalChanged(data) {
      if (this.mode == "editor") return;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.updateEndsNow();
      this.onFilterChanged();
    },
    onDateRangePickerEvent(e) {
      if (e == "show") {
        if (this._prev_scroll == undefined) {
          this._prev_scroll = this.$el.style.overflow;
        }
        this.$el.style.overflow = "visible";
        this.isDatetimePickerVisible = true;
      } else if (e == "hide") {
        if (this._prev_scroll != undefined) {
          this.$el.style.overflow = this._prev_scroll;
        }
        this.isDatetimePickerVisible = false;
      }
    },
    dropdownApply(items) {
      var selected = items.map(function(item) {
        return item.value;
      });
      this.$set(this, "selected", selected);
      this.onFilterChanged();
    },
    onFilterChanged() {
      this.$store.dispatch(`${this.namespace}/setInterval`, {
        start: this.startDate,
        end: this.endDate
      });
      if (this?.dataList?.length && this.mode != "editor") {
        this.fetch();
      } else {
        this.$set(this, "dataset", null);
      }
    },
    fetch() {
      if (this.downloadOnly) {
        return;
      }
      if (!this.selected.length) {
        this.isReady = true;
        return;
      }
      if (this.edges == "fit_in_time_window") {
        // this.$store.dispatch("history/fetchFitInTimeWindow", this.selected);
        this.$store.dispatch(
          `${this.namespace}/fetchFitInTimeWindow`,
          this.selected
        );
      } else {
        // this.$store.dispatch("history/fetch", this.selected); // default;
        this.$store.dispatch(`${this.namespace}/fetch`, this.selected); // default;
      }
    },
    parseEquipmentDataSamples() {
      let dataset = null;
      let reportDataset = [];
      let formatList = {};
      this.$root.config.references.data_value_format_types.forEach((i) => {
        formatList[i.id] = i.format_mask;
      });

      if (this?.dataList?.length && this.history) {
        dataset = [];
        let serie = null;
        const addSamples = (data) => {
          let samples =
            data?.id in this.history ? this.history[data.id].samples : [];

          if (!samples.length) return;
          let dataName = data?.name || "";
          let dataIdentityEmbeddedApp = data?.identity_embedded_app || "";
          let field = this.fields.find(({ data_id }) => data_id == data.id);

          let format =
            data?.value_format_type?.id || 0
              ? formatList[data.value_format_type.id]
              : "";

          if (field.chartOptions) {
            serie = JSON.parse(JSON.stringify(field.chartOptions));
          } else {
            // legacy screens
            let dataColor = field.chart.color;
            serie = {
              name: dataName,
              identity_embedded_app: dataIdentityEmbeddedApp,
              type: "line",
              showSymbol: false,
              hoverAnimation: false,
              itemStyle: {
                color: dataColor
              },
              lineStyle: {
                waveForm:
                  data.portal_data?.wave_form ||
                  ((data?.memory_type?.name || "").match(/(bool|coil)/gi) !=
                    null
                    ? "square"
                    : "triangle")
              }
            };
          }
          serie.dataType = data.type;
          serie.data = samples.map((item) => {
            return {
              name: new Date(item.date_time),
              value: [
                new Date(item.date_time),
                data.type !== "string" ? parseFloat(item.value) : item.value
              ],
              format: format,
              data_id: data.id
            };
          });

          dataset.push(serie);
          reportDataset = reportDataset.concat(
            samples.map((item) => {
              return {
                value:
                  data.type !== "string" ? parseFloat(item.value) : item.value,
                date_time: item.date_time,
                name: dataName,
                data_id: data.id
              };
            })
          );
        };

        let lst = (this.panelDataList || []).length
          ? this.panelDataList
            .filter(({ data_id }) =>
              this.dataList.some(
                ({ id }) => parseInt(id) == parseInt(data_id)
              )
            )
            .map(({ data_id }) =>
              this.dataList.find(
                ({ id }) => parseInt(id) == parseInt(data_id)
              )
            )
          : this.dataList;
        if (this.deviceId) {
          lst = lst.filter(
            ({ device }) => !device || device.id == this.deviceId
          );
        }
        lst.forEach(addSamples);
      }
      this.$set(this, "dataset", dataset);
      this.reportDataset = reportDataset.sort((a, b) => {
        if (a.date_time > b.date_time) return -1;
        if (b.date_time > a.date_time) return 1;
        return 0;
      });
      this.isReady = true;
    },
    initSelection() {
      let self = this;
      let source =
        this.deviceId && (this.equipmentDataList || []).length
          ? this.equipmentDataList.filter(
            ({ device }) =>
              device && parseInt(device.id) == parseInt(this.deviceId)
          )
          : this.equipmentDataList;
      if (source && source.length) {
        let custom_view =
          (self.equipment &&
            self.equipment.portal_data &&
            self.equipment.portal_data.custom_history_data_list) ||
          null;
        var selected = [];
        // filter from panel
        if (this?.panelOptions?.dataList?.length) {
          source.forEach((data) => {
            let cfg = this.panelOptions.dataList.find(
              ({ data_id }) =>
                data_id == data.id || data_id == data.reference_id
            );
            if (cfg && cfg.enabled && cfg.checked) {
              selected.push(data.id);
            }
          });
          // console.log(selected);
          self.$set(self, "selected", selected);
          return;
        }
        // default from equipment
        if (custom_view && custom_view.checked && custom_view.checked.length) {
          selected = source
            .filter((i) => {
              return (custom_view.checked || []).includes(self.dataPath(i));
            })
            .map((i) => i.id);
          self.$set(self, "selected", selected);
        } else {
          let cfg = self.historyConfiguration;
          if (cfg.nSelected) {
            selected = [];
            for (var i = 0; i < cfg.nSelected && i < source.length; i++) {
              selected.push(source[i].id);
            }
            self.$set(self, "selected", selected);
          }
        }
        // }
      }
    },
    dataPath(data) {
      // return (
      //   data.reference_id ||
      //   ((data.device && data.device.name) || "") + "." + data.name
      // );
      let prefix = data?.device?.data_collector_device_id
        ? `${data.device.reference_id}.`
        : "";
      return `${prefix}${data.reference_id}`;
    },
    equipmentData(id) {
      return (this.equipmentDataList || []).find((item) => item.id == id);
    },
    userTimezone(dt) {
      let timezone = this?.portalData?.timezone || "";
      let value = timezone ? moment(dt).tz(timezone) : moment(dt);
      return value;
    },
    setup() {
      if (this.mode === "editor") {
        this.isReady = true;
        this.showDatetimePicker = true;
        this.queryLimit = this.queryLimits[0].value;
        this.initSelection();
        return;
      }

      this.queryLimit = this.queryLimits[0].value;
      this.initSelection();
      this.showDatetimePicker = false;

      // validate if there is already a query result in memory
      let prev = (this?.history?.query?.data_ids || "")
        .split(",")
        .sort()
        .join(",");
      let curr = (this?.selected || []).sort().join(",");
      if (curr != "" && prev != "" && curr == prev) {
        // query is always in utc
        this.startRef = this.userTimezone(
          moment(this.history.query.start + "+0000")
        );
        this.endRef = this.userTimezone(
          moment(this.history.query.end + "+0000")
        );
        //====================
        this.showDatetimePicker = true;
        this.parseEquipmentDataSamples();
      } else {
        this.initIntervalRef();
        this.showDatetimePicker = true;
        this.onFilterChanged();
        this.isReady = true;
      }
    },
    getPanelDataList() {
      return new Promise((resolve) => {
        if (this.panelDataList) {
          let lst = (this.panelDataList || [])
            .map(({ data_id }) => {
              let item =
                this.dashboardDataList.find(
                  ({ id, reference_id }) =>
                    data_id == id || data_id == reference_id
                ) || null;
              if (!item) {
                // console.log(data_id);
              }
              return item;
            })
            .filter((item) => item != null);
          // console.log(
          //   `${lst.length} == ${this?.panelDataList?.length} ${this.dashboardDataList.length}`
          // );
          // if (lst.length == this?.panelDataList?.length) {
          if (lst && this.dashboardDataList.length) {
            // console.log("getPanelDataList");
            // console.log(lst);
            resolve(lst);
            return;
          }
        }
        resolve(null);
      });
    },
    equipmentDataRequest(forceUpdate) {
      if (!this?.panelDataList?.length && this.equipment) {
        var query = {
          resource: "data",
          connectorId: this.equipment.id,
          forceUpdate: forceUpdate,
          once: true
        };
        if (this.$store.getters["deviceId"]) {
          query.device_id = this.$store.getters["deviceId"];
        } else {
          this.fetchedAll = true; // non reactive ! important
        }
        return this.$store.dispatch("dashboard/fetchResourcesFrom", query);
      } else {
        return new Promise((resolve) => {
          if (this.mode != "editor" && !this.hasDataList && (this.$store.getters["dashboard/dataListFromEquipment"] || []).length) {
            resolve(this.$store.getters["dashboard/dataListFromEquipment"]);
          }
          else {
            resolve(null);
          }
        });
      }
    },
    setSideBar() {
      if (this.sidebar.name != "HistoryForm") {
        this.$root.$emit("controlSidebar:setContent", HistoryForm);
      }
    },
    onUserRefresh() {
      if (!this.endsNow) return;
      this.initIntervalRef();
      this.onFilterChanged();
      // required by the jquery calendar to get its visible interface updated
      this.showDatetimePicker = false;
      this.$nextTick(() => {
        this.showDatetimePicker = true;
      });
    }
  },
  beforeCreate() {
    this.service = new DataService();
    this._timer = null;
  },
  mounted() {
    this.mounted = true; // embedded _isMounted seems to not be reactive
  },
  created() {
    if (
      this.namespace !== "history" &&
      !this.$store.hasModule(this.namespace)
    ) {
      this.$store.registerModule(this.namespace, createHistory());
    }
    if (this.hasDataList) {
      this.equipmentDataList =
        this.$store.getters["dashboard/dataListFromEquipment"];
    }
    this.$root.$on("refreshPage", this.onUserRefresh);
  },
  beforeDestroy() {
    if (
      this.namespace !== "history" &&
      this.$store.hasModule(this.namespace) &&
      !this.isLarge
    ) {
      this.$store.unregisterModule(this.namespace);
    }
    this.$root.$off("refreshPage", this.onUserRefresh);
    clearInterval(this._timer);
    this._timer = null;
  }
};
</script>

<style scoped>
.resizable {
  resize: vertical;
}

.box {
  margin: 0;
  padding: 0;
  box-shadow: none;
}

.box > .box-header {
  padding: 0 10px;
}
.nav-tabs-custom {
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.nav-tabs-custom > .nav-tabs > li.header {
  /* padding: 0; */
}
.nav-tabs-custom > .nav-tabs.pull-right > li:hover {
  cursor: pointer;
}

.nav-tabs > li > a {
  padding: 10px;
}

.tab-toolbar-control-group {
  float: right;
  /* max-width: 450px; */
  margin-left: 0;
}

.tab-toolbar-control {
  display: inline-block;
  vertical-align: top;
  margin-left: 0;
}

.tab-content {
  position: relative;
  /* resize: vertical; */
}

/* legacy */
.tab-content-legacy {
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 400px;
  padding-bottom: 10px;
}

.tab-content > .tab-pane {
  height: inherit;
}

.toolbar-title {
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  vertical-align: top;
}

.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}

.clicable-title:hover {
  cursor: pointer;
  opacity: 0.8;
  color: #31708f;
}

.download-options {
  padding: 20px 10px;
  margin: 20px;
  border: 1px solid lightgray;
  border-radius: 5px;
}

.download-confirm p {
  margin-top: 10px;
}

.download-result-controls button {
  margin: 10px;
}

.toolbar-btn-icon {
  margin: initial;
}

@media (max-width: 360px) {
  .toolbar-btn-icon {
    margin: 0 -6px;
  }
}

.show-small {
  display: none;
}
@media (min-width: 360px) and (max-width: 1300px) {
  .hidden-small {
    display: none !important;
  }
  .show-small {
    display: inline-block;
  }
}
</style>
